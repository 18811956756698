<template>
    <div>
        <!-- Pantalla de carga -->
        <v-row v-if="loadingAll">
            <v-col cols="12" class="text-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>

        <!-- Contenido del convenio -->
        <v-row class="mt-6 " v-else>
            <v-col cols="12">
                <img height="auto" width="100%" class="img-banner rounded-lg" :src="convenioDetalles.img_full" />
                <v-card elevation="0" color="#f6f9ff">

                    <v-card-title style="padding: 1.5rem 0;">
                        <h1>{{ convenioDetalles.post_title }}</h1>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <p class="text-small-gray  pt-1 pb-2">
                        {{ formatDate(convenioDetalles.post_date) }}
                    </p>
                    <div class="pb-4  pr-4 style-html" style="color: #626262 !important; text-align: start !important;"
                        v-html="convenioDetalles.description">
                    </div>
                    <template v-if="showVideo">
                        <video style="background-color: #000; width: 95%" ref="videoA" id="videoAutoDetails" controls
                            loop />
                    </template>

                </v-card>
            </v-col>
            <v-col cols="3">
                <!-- Otros convenios o noticias pueden ir aquí -->
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Api from "@/utils/api";
import Hls from "hls.js";


export default {
    data() {
        return {
            convenioDetalles: {},  // Datos del convenio actual
            loadingAll: false,     // Variable de carga
            showVideo: false,      // Mostrar video
            message: {
                dialog: false,
                title: "",
                sms: "",
                type: "",
                redirect: "",
            },
        };
    },
    computed: {
        user() {
            return this.$store.getters.getUser; // Obtener el usuario desde Vuex
        },
        currentUser() {
            return this.user || {};  // No lo modificamos, solo accedemos
        },
        getCountry() {
            const currentUser = this.$store.getters.getUser;
            return currentUser ? {
                country: currentUser.country || '',
                headquarters: currentUser.headquarters || '',
                headquarters_city: currentUser.headquarters_city || ''
            } : {
                country: '',
                headquarters: '',
                headquarters_city: ''
            };
        },
    },
    watch: {
        'convenioDetalles.description': function () {
            this.adjustImageAlignment();
        },

        currentUser(newUser) {
            if (newUser && Object.keys(newUser).length > 0) {
                this.getConvenioDetallesFromRoute();
            }
        },
        getCountry(newCountry, oldCountry) {
            console.log(newCountry);
        },
    },
    created() {
        this.getToken();
        if (this.currentUser && Object.keys(this.currentUser).length > 0) {
            this.getConvenioDetallesFromRoute();
        }
    },
    mounted() {
    },
    updated() {
        this.adjustImageAlignment();
    },
    methods: {
        getConvenioDetalles(convenioId) {
            if (this.currentUser && this.currentUser.profile) {
                const { document_number: cc, profile: { id: loginId }, country, headquarters_city: ciudad, headquarters: sede } = this.currentUser;
                const data = { pais: country, ciudad, sede };

                this.loadingAll = true;
                
                Api.noAuth()
                    .getConvenios(cc, loginId, data)
                    .then((resp) => resp.json())
                    .then((data) => {
                        if (data.cod === 0) {
                            const convenio = data.data.find(item => item.post_id === convenioId);
                            if (convenio) {
                                this.convenioDetalles = convenio;
                                this.adjustImageAlignment();
                                if (this.convenioDetalles.url_video) {
                                    let videoSrc = this.convenioDetalles.url_video;
                                    let format = videoSrc.includes('.m3u8');
                                    let vimeo = videoSrc.includes('vimeo');
                                    if (format) {
                                        this.showVideo = true;
                                        setTimeout(() => {
                                            let video = document.getElementById("videoAutoDetails");
                                            let videoMobile = document.getElementById(
                                                "videoAutoDetailsMobile"
                                            );
                                            video.src = videoSrc;
                                            if (screen.width < 1024) {
                                                videoMobile.src = videoSrc;
                                            }
                                            if (Hls.isSupported()) {
                                                let hls = new Hls();
                                                hls.loadSource(videoSrc);
                                                hls.attachMedia(video);
                                                let hlsMobile = new Hls();
                                                hlsMobile.loadSource(videoSrc);
                                                hlsMobile.attachMedia(videoMobile);
                                            } else if (
                                                video.canPlayType("application/vnd.apple.mpegurl")
                                            ) {
                                                video.src = videoSrc;
                                                videoMobile.src = videoSrc;
                                            }
                                        }, 1500);
                                    }
                                }

                            } else {
                                //no tiene permiso para ver el convenio
                                this.$router.push('/convenios/');
                                this.loadingAll = false;
                                return;
                            }
                        } else {
                            this.showErrorMessage(data.message);
                        }
                    })
                    .catch(() => {
                        this.showErrorMessage("No se pudo obtener los detalles del convenio.");
                    })
                    .finally(() => {
                        this.loadingAll = false;
                    });
            } else {
                this.showErrorMessage("Datos del usuario no disponibles.");
            }
        },



        showErrorMessage(message) {
            this.message = {
                dialog: true,
                title: "Error",
                sms: message,
                type: "error",
                redirect: "",
            };
        },

        getToken() {
            const token = localStorage.getItem("token");
            const tokenb64 = localStorage.getItem("tokenB64");
            this.token = tokenb64;
            const div = token.split(".");
            if (div.length >= 2) {
                this.infoToken = JSON.parse(atob(div[1]));
                const nameList = this.infoToken.fullName.split(" ");
                this.name = nameList[0] || nameList[1] || "";
            } else {
                this.infoToken = { document: "" };
            }
        },

        getConvenioDetallesFromRoute() {
            const convenioId = this.$route.params.id;
            if (convenioId) {
                this.getConvenioDetalles(convenioId);
            } else {
                this.showErrorMessage("El ID del convenio no está disponible.");
            }
        },

        formatDate(dateString) {
            if (!dateString || isNaN(Date.parse(dateString))) {
                return "";
            }
            const date = new Date(dateString);
            return date.toISOString().split('T')[0];
        },

        adjustImageAlignment() {
            this.$nextTick(() => { // Espera a que Vue termine de actualizar el DOM
                const images = document.querySelectorAll('.style-html div img');
                images.forEach((img) => {
                    // Crear un nuevo contenedor <div> para la imagen
                    const parentDiv = document.createElement('div');
                    img.parentNode.insertBefore(parentDiv, img); // Insertamos el div antes de la imagen
                    parentDiv.appendChild(img); // Movemos la imagen dentro del nuevo div

                    // Añadir la clase correspondiente al <div> basado en la alineación de la imagen
                    if (img.classList.contains('aligncenter')) {
                        parentDiv.classList.add('text-center');
                    } else if (img.classList.contains('alignleft')) {
                        parentDiv.classList.add('text-left');
                    } else if (img.classList.contains('alignright')) {
                        parentDiv.classList.add('text-right');
                    }
                });
            });
        }

    },
};
</script>


<style scoped>
.style-html ::v-deep img {
    max-width: 100%;
    height: auto;
}

#videoAutoDetails:hover {
    cursor: pointer;
}
</style>